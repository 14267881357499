import { Flex } from '@chakra-ui/react';
import { BgWrapper, ContentWrapper } from '@layout/index';
import Title from '@atoms/Title';

import ConsumerFilter from './components/ConsumerFilter';
import ConsumerTable from './components/ConsumerTable';
import useSearchOptions from '@hooks/useSearchOptions';
import { Tables } from '@/types/index';
import { useRecoilState, useRecoilValue } from 'recoil';
import { consumerInfoState } from '@store/consumerState';
import React from 'react';
import Loading from '@atoms/Loading';
import { useQuery } from '@apollo/client';
import { currentTableInfoFamily } from '@store/family/tableFamily';
import { USER_SEARCH, makeUserInfoVariables } from '@graphql/consumer';

interface Props {
  pageId: number;
}

const Consumer: React.FC<Props> = ({ pageId }) => {
  useSearchOptions(Tables.User);

  const [infos, setInfos] = useRecoilState(consumerInfoState);
  const { tStartDate, tEndDate, tKeyword, tStatus, tSearchOption, tPerPage, tCurrentPage } =
    useRecoilValue(currentTableInfoFamily(Tables.User));

  useQuery(USER_SEARCH, {
    variables: makeUserInfoVariables({
      startDate: tStartDate,
      endDate: tEndDate,
      keyword: tKeyword,
      status: tStatus,
      option: tSearchOption,
      perPage: tPerPage,
      page: tCurrentPage,
    }),
    skip: pageId === 0,
    onCompleted: (data) => setInfos(data),
  });

  const isLoading = !infos || !pageId;
  // if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <Flex flexDir="column">
      <Title title="회원 정보" />
      <ContentWrapper>
        <BgWrapper>
          <ConsumerFilter />
          <ConsumerTable />
        </BgWrapper>
      </ContentWrapper>
    </Flex>
  );
};

export default Consumer;
