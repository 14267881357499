import { Paths } from '@pages/Router';
import { getToken } from '@utils/token';
import { useLocation } from 'react-router-dom';
import { atom, selector, useRecoilValue } from 'recoil';
import { PageInfo, UserInfo } from '../types';
import { getAllowedUrls } from '@utils/index';

export const userInfoState = atom<UserInfo>({
  key: 'userState/userInfo',
  default: {
    id: '',
    name: '',
    partnerPages: [],
    coin: { id: '' },
  },
});

export const PathsSelector = selector({
  key: 'userState/paths',
  get: ({ get }) => {
    const userInfo = get(userInfoState);
    const allowedUrls = getAllowedUrls(
      Paths,
      userInfo.partnerPages.map((el) => el.url),
    );

    // 메뉴가될 인포 정렬
    const parentPaths = userInfo.partnerPages
      .filter((el) => el.depth === 1)
      .sort((a, b) => a.order_by_no - b.order_by_no);

    // 서브메뉴가 될 인포 필터링
    const childPaths = userInfo.partnerPages.filter((el) => el.depth === 2);

    // 서브메뉴들의 부모아이디 별 구분
    const pathByParentId: { [key in string]: PageInfo[] } = {};
    childPaths.forEach((pathInfo) => {
      if (!pathByParentId[pathInfo.parent_id]) {
        pathByParentId[pathInfo.parent_id] = [pathInfo];
      } else {
        pathByParentId[pathInfo.parent_id].push(pathInfo);
      }
    });

    // 서브메뉴들 사이의 순서 정렬
    for (const parentId in pathByParentId) {
      pathByParentId[parentId] = pathByParentId[parentId].sort(
        (a, b) => a.order_by_no - b.order_by_no,
      );
    }

    // 메뉴에 서브메뉴정보 추가
    const paths = parentPaths.map((el) => {
      if (Object.keys(pathByParentId).includes(el.id)) {
        return { ...el, child: pathByParentId[el.id] };
      } else {
        return el;
      }
    });

    return { allowedUrls, paths };
  },
});

export const isLoggedInSelector = selector({
  key: 'userState/isLoggedIn',
  get: ({ get }) => {
    const userInfo = get(userInfoState);
    const accessToken = getToken();

    if (userInfo.name && accessToken) {
      return true;
    } else {
      return false;
    }
  },
});

export function useCurrentPageId() {
  const { pathname } = useLocation();
  const { partnerPages } = useRecoilValue(userInfoState);

  const currentPageInfo = partnerPages.filter((el) => el.url === pathname)[0];
  const pageId = Number(currentPageInfo?.id) || 0;

  return { pageId };
}
