import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { Flex, VStack, HStack, Button, Input, Text, Icon } from '@chakra-ui/react';
import { Paths } from '@pages/Router';
import { useMutation } from '@apollo/client';
import { LOGIN_USER } from '@graphql/user';
import useInput from '@hooks/useInput';
import { AiOutlineLine } from 'react-icons/ai';
import { getToken, setLoginInfo } from '@utils/token';
import useKeyDown, { KeyboardKey } from '@hooks/useKeyDown';
import { userInfoState } from '@store/userState';

const Login = () => {
  const navigate = useNavigate();
  const [email, handleEmail] = useInput('');
  const [password, handlePassword] = useInput('');
  const setUserInfo = useSetRecoilState(userInfoState);

  const [loginUser] = useMutation(LOGIN_USER, {
    variables: { name: email, password },
    onCompleted: ({ adminLoginByPartner }) => {
      // 접근가능한 페이지가 피어있는 유저는 블라킹
      if (adminLoginByPartner.partner.partnerPages.length === 0) {
        alert('접근 권한이 없는 아이디 입니다.');
        return;
      }
      setLoginInfo(adminLoginByPartner.access_token, adminLoginByPartner.expires_in);
      setUserInfo(adminLoginByPartner.partner);
      navigate(Paths.Dashboard);
    },
    onError: (error) => {
      alert(error.message);
    },
  });

  const handleKeyDown = useKeyDown(() => {
    loginUser();
  }, [KeyboardKey.enter]);

  useEffect(() => {
    if (getToken()) navigate(Paths.Dashboard);
  }, [navigate]);

  return (
    <Flex flex={1} direction="column" justify={'center'}>
      <Text fontWeight={'extrabold'} letterSpacing={-1} fontSize="3xl">
        TMO Wallet
      </Text>
      <Flex mt={-2} ml={-2}>
        <Icon as={AiOutlineLine} h={6} w={6} mt={1} style={{ transform: 'rotate(90deg)' }} />
        <Text ml={-1} fontSize="xl" fontStyle={'italic'}>
          Partners Login
        </Text>
      </Flex>
      <HStack mt={6} spacing={2}>
        <VStack spacing={2}>
          <Input
            focusBorderColor="purple.400"
            placeholder="Id"
            size="md"
            value={email}
            onChange={handleEmail}
            w={60}
          />
          <Input
            focusBorderColor="purple.400"
            placeholder="Password"
            size="md"
            type="password"
            value={password}
            onChange={handlePassword}
            onKeyDown={handleKeyDown}
            autoComplete="off"
            w={60}
          />
        </VStack>
        <Button h="100%" w="24" colorScheme="purple" onClick={() => loginUser()}>
          로그인
        </Button>
      </HStack>
    </Flex>
  );
};

export default Login;
