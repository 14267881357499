import { Flex } from '@chakra-ui/react';
import { BgWrapper, ContentWrapper } from '@layout/index';
import Title from '@atoms/Title';
import UseTxTable from './components/UseTxTable';
import UseTxFilter from './components/UseTxFilter';
import UseTxSummary from './components/UseTxSummary';
import Loading from '@atoms/Loading';
import useSearchOptions from '@hooks/useSearchOptions';
import { Tables } from '@/types/index';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentTableInfoFamily, tCoinOptionStatusFamily } from '@store/family/tableFamily';
import { useQuery } from '@apollo/client';
import { USE_TX, makeSwapVariables } from '@graphql/transaction';
import { useTxInfoState } from '@store/useTxState';
import React from 'react';

interface Props {
  pageId: number;
}

const UseTx: React.FC<Props> = ({ pageId }) => {
  const { coinOptions } = useSearchOptions(Tables.Use);
  const tCoinOptionStatus = useRecoilValue(tCoinOptionStatusFamily(Tables.Use));
  const currentSwapDir = coinOptions?.filter(
    (option) => Number(option.key) === Number(tCoinOptionStatus),
  )[0].value;

  const [infos, setInfos] = useRecoilState(useTxInfoState);
  const {
    tStartDate,
    tEndDate,
    tKeyword,
    tStatus,
    tCoinOption,
    tSearchOption,
    tPerPage,
    tCurrentPage,
  } = useRecoilValue(currentTableInfoFamily(Tables.Use));

  useQuery(USE_TX, {
    variables: makeSwapVariables({
      pageId: pageId,
      coinId: tCoinOption,
      startDate: tStartDate,
      endDate: tEndDate,
      keyword: tKeyword,
      status: tStatus,
      option: tSearchOption,
      perPage: tPerPage,
      page: tCurrentPage,
    }),
    skip: pageId === 0 || !tCoinOption,
    onCompleted: (data) => setInfos(data),
  });

  const isLoading = !infos || !pageId;
  // if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <Flex flexDir="column">
      <Title title="사용 내역 관리" subTitle={currentSwapDir} />
      <ContentWrapper>
        <UseTxSummary />
        <BgWrapper>
          <UseTxFilter />
          <UseTxTable pageId={pageId} />
        </BgWrapper>
      </ContentWrapper>
    </Flex>
  );
};

export default UseTx;
