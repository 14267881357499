import { Flex } from '@chakra-ui/react';
import { BgWrapper, ContentWrapper } from '@layout/index';
import Title from '@atoms/Title';
import SubTitle from '@atoms/SubTitle';
import BillFilter from './components/BillFilter';
import BillHistorySummary from './components/BillHistorySummary';
import BillDetailTable from './components/BillDetailTable';
import Loading from '@atoms/Loading';
import React from 'react';
import { Tables } from '@/types/index';
import useSearchOptions from '@hooks/useSearchOptions';
import { useRecoilState, useRecoilValue } from 'recoil';
import { billHistoryInfoState } from '@store/billHistoryState';
import { useQuery } from '@apollo/client';
import { BILL_HISTORY } from '@graphql/billHistory';
import {
  currentTableInfoFamily,
  tCoinOptionStatusFamily,
  tMonthFamily,
  tYearFamily,
} from '@store/family/tableFamily';

interface Props {
  pageId: number;
}

const BillHistory: React.FC<Props> = ({ pageId }) => {
  const { coinOptions } = useSearchOptions(Tables.Bill);
  const tCoinOptionStatus = useRecoilValue(tCoinOptionStatusFamily(Tables.Bill));
  const currentSwapDir = coinOptions?.filter(
    (option) => Number(option.key) === Number(tCoinOptionStatus),
  )[0].value;

  const [infos, setInfos] = useRecoilState(billHistoryInfoState);
  const tYear = useRecoilValue(tYearFamily(Tables.Bill));
  const tMonth = useRecoilValue(tMonthFamily(Tables.Bill));
  const { tKeyword, tCoinOption, tSearchOption, tPerPage, tCurrentPage } = useRecoilValue(
    currentTableInfoFamily(Tables.Bill),
  );

  useQuery(BILL_HISTORY, {
    variables: {
      year: tYear,
      month: tMonth,
      keyword: tKeyword,
      option: tSearchOption,
      perPage: tPerPage,
      page: tCurrentPage,
      pageId: pageId,
      coinId: Number(tCoinOption),
    },
    skip: pageId === 0 || !tCoinOption,
    onCompleted: (data) => setInfos(data),
  });

  const isLoading = !infos || !pageId;
  // if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <Flex flexDir="column">
      <Title title="월별 정산 내역" subTitle={currentSwapDir} />
      <ContentWrapper>
        <BgWrapper mb={6}>
          <BillFilter pageId={pageId} />
        </BgWrapper>

        <BillHistorySummary />

        <SubTitle text="상세 내역" />
        <BgWrapper mt={6}>
          <BillDetailTable />
        </BgWrapper>
      </ContentWrapper>
    </Flex>
  );
};

export default BillHistory;
