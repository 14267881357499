import { useLocation } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import NavItem from '@articles/NavItem';
import MenuButtons from '@articles/MenuButtons';
import { PathsSelector } from '@store/userState';
import { useRecoilValue } from 'recoil';
import React from 'react';
import { MdMenu } from 'react-icons/md';

interface Props {
  isShowSideBar: boolean;
  onShowSidebarClick: () => void;
}
const Sidebar: React.FC<Props> = ({ isShowSideBar, onShowSidebarClick }) => {
  const { pathname } = useLocation();
  const { paths } = useRecoilValue(PathsSelector);

  const isActiveUrl = (_currentPath: string, _infoPath: string): boolean => {
    if (_infoPath === '/') {
      return _currentPath === _infoPath;
    } else {
      return _currentPath.includes(_infoPath);
    }
  };

  return (
    <Flex
      direction="column"
      px="4"
      py={4}
      gap={3}
      position="sticky"
      top={0}
      minWidth={isShowSideBar ? 200 : 16}
      minH={'100vh'}
      w={isShowSideBar ? 56 : 16}
      borderRight="2px solid #f5f5f5"
      transition="width 0.2s"
      onClick={(e) => e.stopPropagation()}
    >
      {!isShowSideBar && (
        <Flex justifyContent={'center'}>
          <MdMenu size={42} onClick={onShowSidebarClick} />
        </Flex>
      )}

      {isShowSideBar &&
        paths.map((pathInfo) => {
          if (!pathInfo.is_folding_menu) {
            return (
              <NavItem
                key={pathInfo.id}
                title={pathInfo.name}
                active={isActiveUrl(pathname, pathInfo.url)}
                path={pathInfo.url}
              />
            );
          } else if (pathInfo.child) {
            return (
              <MenuButtons
                key={pathInfo.id}
                currentPath={pathname}
                active={pathInfo.child.map((el) => el.url).includes(pathname)}
                title={pathInfo.name}
                info={pathInfo.child}
              />
            );
          } else {
            return <></>;
          }
        })}
    </Flex>
  );
};

export default Sidebar;
