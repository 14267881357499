import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex } from '@chakra-ui/react';
import { Paths } from '@pages/Router';
import { removeLoginInfo } from '@utils/index';
import useModal from '@hooks/useModal';
import { MODAL_TYPES } from '@components/modals/Modals';
import { ConfirmModalProps } from '@components/modals/ConfirmModal';
import { useRecoilValue } from 'recoil';
import { userInfoState } from '@store/userState';

const Header = () => {
  const navigate = useNavigate();
  const { openModal } = useModal();
  const userInfo = useRecoilValue(userInfoState);

  const onLogoutClick = () => {
    removeLoginInfo();
    navigate(Paths.Login);
    window.location.reload();
  };

  return (
    <Flex
      height={16}
      align={'center'}
      justify="space-between"
      p={4}
      borderBottom="2px solid #f5f5f5"
      mx={2}
    >
      <Box fontFamily="monospace" fontSize={20} fontWeight={600}>
        TMO-Partners
      </Box>
      <Flex alignItems={'center'} gap={4}>
        <div>{userInfo.name}</div>
        <Button
          colorScheme="red"
          onClick={() => {
            openModal<ConfirmModalProps>({
              type: MODAL_TYPES.confirm,
              message: '로그아웃 하시겠습니까?',
              title: 'Logout',
              confirm: onLogoutClick,
            });
          }}
        >
          Logout
        </Button>
      </Flex>
    </Flex>
  );
};

export default Header;
