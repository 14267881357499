import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
  mutation adminLoginByPartner($name: String!, $password: String!) {
    adminLoginByPartner(input: { name: $name, password: $password }) {
      access_token
      expires_in
      partner {
        id
        name
        coin {
          id
        }
        partnerPages {
          id
          parent_id
          url
          name
          depth
          order_by_no
          is_folding_menu
          parent {
            id
            parent_id
            url
            name
            depth
            order_by_no
            is_folding_menu
          }
        }
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken {
    refreshToken {
      access_token
      expires_in
    }
  }
`;

export const CURRENT_PARTNER = gql`
  query currentPartner {
    currentPartner {
      id
      name
      coin {
        id
      }
      partnerPages {
        id
        parent_id
        url
        name
        depth
        order_by_no
        is_folding_menu
        parent {
          id
          parent_id
          url
          name
          depth
          order_by_no
          is_folding_menu
        }
      }
    }
  }
`;
