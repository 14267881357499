import { Flex, Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const ContentWrapper = styled(Flex)`
  flex-direction: column;
  flex: 1;
  padding: 36px 0;
  min-width: 1040px;
`;

export const BgWrapper = styled(Box)`
  background-color: white;
  border-radius: 0.4rem;
`;
