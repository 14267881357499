import { Outlet } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import Sidebar from '@sections/Sidebar';
import Header from '@sections/Header';
import { useState } from 'react';

const Layout = () => {
  const [isShowSideBar, setIsShowSideBar] = useState(false);
  const toggleShowSideBar = () => setIsShowSideBar((prev) => !prev);
  return (
    // <Flex minH="100vh" onClick={() => setIsShowSideBar(false)}>
    //   <Sidebar isShowSideBar={isShowSideBar} onShowSidebarClick={toggleShowSideBar} />
    //   <Flex direction="column" flex={1} overflowY="scroll" h={'100vh'} bg="blackAlpha.50">
    //     <Header />
    //     <Outlet />
    //   </Flex>
    // </Flex>

    <Flex
      direction="column"
      flex={1}
      overflowY="scroll"
      h={'100vh'}
      onClick={() => setIsShowSideBar(false)}
    >
      <Header />
      <Flex direction="row">
        <Sidebar isShowSideBar={isShowSideBar} onShowSidebarClick={toggleShowSideBar} />
        <Box px={8} py={4} minW="900px" flex={1}>
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Layout;
